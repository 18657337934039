import React, { Fragment } from 'react'
import Marquee from 'react-fast-marquee'
import { graphql } from 'gatsby'
import { Composition, Box, useMediaQuery } from 'atomic-layout'

import { vwpx, fluidFontSize } from '@/utils'
import { desktopTemplateCols } from '@/theme'
import { TeaserLink } from '@/pages/index'
import Teaser from '@/components/Teaser'
import Text from '@/components/Text'
import Footer from '@/components/Footer'

const PageHeroVideoBlock = ({
  contentfulPageHeroVideoBlock: {
    media: { desktopPosterImage, desktop },
  },
}) => {
  const isMobile = useMediaQuery({ maxWidth: '500px' })

  return (
    <Box colStart="full-start" colEnd="full-end" css={{ position: 'relative' }}>
      <div
        className="introVideo"
        css={{
          position: 'absolute',
          bottom: '55px',
          left: '50%',
          transform: 'translateX(-50%)',
          fontSize: fluidFontSize(14),
          fontFamily: 'Circular',
          fontWeight: 'normal',
          color: 'rgb(255,255,255)',
          textAlign: 'center',
        }}
      >
        scrollen
      </div>
      <video
        css={{
          height: 'calc(50vh - 40px)',
          display: 'block',
          width: '100%',
          backgroundColor: '#000',
          objectFit: 'cover',
        }}
        poster={desktopPosterImage}
        autoPlay
        muted
        loop
      >
        <source src={desktop.file.url} type={desktop.file.contentType} />
      </video>

      <div
        className="videoPlayer"
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: 'calc(50vh - 40px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily: 'Circular, Arial',
          fontWeight: 900,
          fontSize: '20vw',
          color: 'transparent',
          pointerEvents: 'none',
          WebkitTextStroke: '0.02em white',
          '@media(max-width: 500px)': {
            fontSize: '70vw',
            WebkitTextStroke: '0.01em white',
          },
        }}
      >
        <Marquee
          style={{
            background: 'transparent',
            overflowY: 'hidden',
          }}
          speed={isMobile ? '40' : '60'}
          direction="left"
          delay="0.05"
          gradient="false"
          gradientWidth="0"
        >
          <div
            css={{
              marginRight: vwpx(100),
              '@media(max-width: 500px)': {
                marginRight: vwpx(300),
              },
            }}
          >
            ERROR 404
          </div>
        </Marquee>
      </div>
    </Box>
  )
}

const PageHeroTeaserBlock = () => (
  <Fragment>
    <Box
      colStart="inset-start"
      colEnd="column-center-end"
      colStartMobile="content-start"
      colEndMobile="content-end"
      as={Text}
      type="largeHeadlineSerif"
      css={{
        marginBottom: vwpx(120),
        lineHeight: 1.1,
        '@media(max-width: 500px)': {
          fontSize: fluidFontSize(47.5, 500),
        },
      }}
    >
      Wir konnten die Seite nicht finden
    </Box>
    <Box
      colStart="column-right-start"
      colEnd="content-end"
      rowStartMobile={2}
      colStartMobile="content-start"
      colEndMobile="content-end"
      css={{
        '@media(max-width: 500px)': {
          marginTop: '-20px',
          marginBottom: vwpx(120),
        },
      }}
    >
      <Teaser
        text={
          <Text
            type="teaserText"
            css={{
              lineHeight: 1.6,
              '@media(max-width: 500px)': {
                fontSize: fluidFontSize(13),
              },
            }}
          >
            Der HTML-Fehlercode 404 bedeutet, dass der angeforderte Inhalt auf
            einer Website nicht gefunden wurde. Das ist zum Besipiel dann der
            Fall, wenn euch ein veralteter Link auf eine Seite schickt, die gar
            nicht mehr existiert, oder wenn ihr euch bei der Eingabe der URL
            vertippt habt.
          </Text>
        }
        link={
          <TeaserLink
            css={{
              lineHeight: 1.6,
              '@media(max-width: 500px)': {
                fontSize: fluidFontSize(13),
                marginTop: vwpx(85),
              },
            }}
            href="https://fondof.de"
            label="Zurück zu Home"
          />
        }
      />
    </Box>
  </Fragment>
)

const NotFoundPage = ({ nodes }) => {
  const gapRow = vwpx(150)
  const [contentfulPageHeroVideoBlock] = nodes
  return (
    <Fragment>
      <Composition
        templateCols={desktopTemplateCols}
        gapRow={gapRow}
        css={{
          minHeight: '100vh',
        }}
      >
        <PageHeroVideoBlock
          contentfulPageHeroVideoBlock={contentfulPageHeroVideoBlock}
        />
        <PageHeroTeaserBlock />
      </Composition>
      <Footer onVisibilityChange={() => {}} />
    </Fragment>
  )
}

export default ({
  data: {
    allContentfulPageHeroVideoBlock: { nodes },
  },
}) => <NotFoundPage nodes={nodes} />

export const query = graphql`
  {
    allContentfulPageHeroVideoBlock(
      filter: {
        internalTitle: { eq: "Page Hero Video Block // Intro without Sticker" }
      }
    ) {
      nodes {
        id
        internalTitle
        media {
          desktop {
            file {
              url
              contentType
            }
          }
          desktopPosterImage {
            fixed {
              src
              srcSet
            }
          }
        }
      }
    }
  }
`
